body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Arial, sans-serif;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

h1 {
  color: #61dafb;
}

label, input, button {
  display: block;
  margin-bottom: 0.5em;
}

button {
  cursor: pointer;
  background-color: #61dafb;
  border: none;
  padding: 0.5em 1em;
  color: #282c34;
  font-weight: bold;
}

p {
  font-size: 1.5em;
  font-weight: bold;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1em 0;
  z-index: 1;
}

a {
  color: #61dafb;
  text-decoration: none;
}

.nav {
  position: fixed; /* Add this line */
  top: 0; /* Add this line */
  right: 0; /* Add this line */
  padding: 20px; /* Add this line */
  background-color: #282c34;
  z-index: 1;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav li {
  display: inline;
  margin-left: 10px; /* Change this line */
}

.nav a {
  color: #61dafb;
  text-decoration: none;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.about-content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60vw; /* Use viewport width instead of % */
  margin: auto;
  vertical-align: middle;
  overflow-y: auto;
  margin-top: 10vh; /* Use viewport height instead of em */
  margin-bottom: 5vh;
}

/* When the width of the viewport is 1080px or less */
@media (max-width: 1080px) {
  .about-content {
    max-width: 80vw; /* Increase max-width */
    margin-top: 10vh; /* Increase margin-top */
  }
}
